@import '../../../assets/scss/variables';

.header {
  padding: 40px 0;
  background: #cde6e6;
  margin-bottom: 32px;

  h6 {
    font-weight: 700;
    cursor: pointer;
    margin-bottom: 12px;
  }

  h1 {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: $secondary;
    margin: 0 auto;
  }
}

.subscriptionPlanContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;

  h1 {
    margin-bottom: 0;
  }
}

.subscriptionPlanTitle {
  width: 50%;
  margin: auto 0;
}

.subscriptionPlanButtons {
  margin: auto 0;
}

.planListContainer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 20px;
  padding-bottom: 2rem;
}

.planCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  max-width: 33.33%;
  box-sizing: border-box;
  max-height: 560px;
  flex-basis: 267px;
  height: 560px;

  h4 {
    color: $text-secondary;
    text-align: center;
    margin-bottom: 4px;
  }

  label {
    color: $text-secondary;
    text-align: center;
  }

  p {
    color: $text-secondary;
    text-align: center;
    margin-bottom: 0;
    margin-top: 24px;
  }
}

.costContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  h1 {
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
    margin: 0;
  }

  div {
    margin: auto 0;

    label {
      width: fit-content;
      text-align: left;
      margin: 0;
    }
  }
}

.priceText {
  max-width: 100px;
}
