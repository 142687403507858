.loading-container-course {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.course-view {
  padding-bottom: 40px;
  div{
    margin: 0;
  }
  &__calendar{
    padding-top: 24px;
    .nav-section{
      padding-bottom: 24px;
    }
  }
  &__header {
    background-color: #E1F2F2;
    padding-right: 47px;
    padding-left: 47px;
    padding-top: 24px;
    &__top{
      display: flex;
      flex-direction: row;

      h1{
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        margin: 0px;
        padding-right: 16px;
      }
      .grade-level{
        display: flex;
        align-items: self-end;
        padding-bottom: 6px;
        flex: 1 1;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        color: rgba(16, 35, 40, 0.7);
      }
      .option-select{
        background-color: #F8C341;
        border-radius: 8px;
        margin-left: 12px;
        .ant-select-selection-item {
          div{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap
          }
        }
      }
    }
    &__bottom {
      padding-top: 12px;
      padding-bottom: 17px;
     &__left {
      display: flex;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: rgba(16, 35, 40, 0.7);
      span{
        margin: 0px;
      }
      .course-schedule{
        padding-left: 25px;
      }
      .fa-calendar{
        margin: 0px;
        padding-top: 2px;
        width: 16px;
        height: 18px;
      }
      .fa-clock{
        margin: 0px;
        padding-top: 2px;
        width: 16px;
        height: 18px;
      }
     }
    }
  }
  .tab-wrap{
    display: flex;
    flex-direction: row;
    background-color: #E1F2F2;
    padding-left: 47px;
    padding-right: 47px;
    .ant-tabs{
      // padding-right: 47px;
      // padding-left: 47px;
      background-color: #E1F2F2;
      // .ant-tabs-tab-active {
      //   color: #102328;
      .ant-tabs-tab-active{
        color: #102328;
        .ant-tabs-tab-btn{
          color: #102328;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          padding-bottom: 6px;
        }
      }
      .ant-tabs-tab:hover {
        color: #102328;
      }
      .ant-tabs-ink-bar{
        background-color: #F36D35;
      }
    }
    .instruction-days{
      margin: 0px;
      margin-left: auto;
      font-family: 'IBM Plex Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #102328;
      span {
        padding-left: 8px;
        padding-right: 24px;
        font-weight: 700;
        font-size: 14px;
      }
    }
    .lessons-planned{
      margin:0px;
      font-family: 'IBM Plex Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #102328;
      span{
        padding-left: 8px;
        font-weight: 700;
        font-size: 14px;
      }
    }
  }
 .timeline__container {
  .timeline {
    padding-top: 27px;
    .gantt-view__legend__container{
      padding-left: 47px;
    }
  }
 }
}