.table {
  width: 100%;

  tfoot td {
    border-bottom: 1px solid #f1f1f1;
    border-left: 1px solid #f1f1f1;
    border-right: 1px solid #f1f1f1;
    padding: 12px 24px;
  }
}
