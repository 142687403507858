@import '../../../assets/scss/variables';

.subscriptionPlanType {
  color: $white;
  margin-bottom: 4px;
}

.subscriptionUser {
  color: $white;
}

.subscriptionUserEmail {
  color: $white;
  margin-bottom: 32px;
}

.subscriptionPaymentLabel {
  color: $white;
  margin-bottom: 4px;
}

.subscriptionPaymentPrice {
  color: $white;
  margin-bottom: 32px;
}

.subscriptionIndividualPaymentLabel {
  color: $white;
  margin-bottom: 4px;
}

.subscriptionIndividualPaymentPrice {
  color: $white;
}
