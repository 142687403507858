@charset "UTF-8";

@font-face {
  font-family: 'tara';
  src: url('../fonts/tara.eot');
  src: url('../fonts/tara.eot?#iefix') format('embedded-opentype'), url('../fonts/tara.woff') format('woff'),
    url('../fonts/tara.ttf') format('truetype'), url('../fonts/tara.svg#tara') format('svg');
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: 'tara' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'tara' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add:before {
  content: '\61';
}

.icon-arrow:before {
  content: '\62';
}

.icon-article:before {
  content: '\63';
}

.icon-auto-stories:before {
  content: '\64';
}

.icon-calendar-view-day:before {
  content: '\65';
}

.icon-calendar-view-week:before {
  content: '\66';
}

.icon-center-focus-weak:before {
  content: '\67';
}

.icon-chair-alt:before {
  content: '\68';
}

.icon-class:before {
  content: '\69';
}

.icon-dashboard:before {
  content: '\6a';
}

.icon-done:before {
  content: '\6b';
}

.icon-edit:before {
  content: '\6c';
}

.icon-email:before {
  content: '\6d';
}

.icon-poll:before {
  content: '\6e';
}

.icon-play-lesson:before {
  content: '\6f';
}

.icon-person:before {
  content: '\70';
}

.icon-notifications:before {
  content: '\71';
}

.icon-looks-one:before {
  content: '\72';
}

.icon-lock:before {
  content: '\73';
}

.icon-language:before {
  content: '\74';
}

.icon-link:before {
  content: '\75';
}

.icon-keyboard-arrow-down:before {
  content: '\76';
}

.icon-home:before {
  content: '\77';
}

.icon-help:before {
  content: '\78';
}

.icon-flag:before {
  content: '\79';
}

.icon-emoji-emotions:before {
  content: '\7a';
}

.icon-radar:before {
  content: '\41';
}

.icon-remove:before {
  content: '\42';
}

.icon-school:before {
  content: '\43';
}

.icon-search:before {
  content: '\44';
}

.icon-settings:before {
  content: '\45';
}

.icon-star:before {
  content: '\46';
}

.icon-today:before {
  content: '\47';
}

.icon-topic:before {
  content: '\48';
}

.icon-watch-later:before {
  content: '\49';
}

.icon-web:before {
  content: '\4a';
}

.icon-courses:before {
  content: '\4b';
}

.icon-dashboard-1:before {
  content: '\4c';
}

.icon-logout:before {
  content: '\4d';
}

.icon-settings-fill:before {
  content: '\4e';
}

.icon-dubble-right:before {
  content: '\4f';
}

.icon-backpack:before {
  content: '\50';
}

.icon-bell:before {
  content: '\51';
}

.icon-books:before {
  content: '\52';
}

.icon-calendar-default-state:before {
  content: '\53';
}

.icon-caret-down:before {
  content: '\54';
}

.icon-clipboard:before {
  content: '\56';
}

.icon-grip-dots-vertical:before {
  content: '\55';
}

.icon-icons-apple:before {
  content: '\57';
}

.icon-chalkboard-user:before {
  content: '\58';
}

.icon-clock-fill:before {
  content: '\59';
}

.icon-memo-fill:before {
  content: '\5a';
}

.icon-note:before {
  content: '\30';
}

.icon-pen-ruler:before {
  content: '\31';
}

.icon-notebook:before {
  content: '\32';
}

.icon-clock-desk:before {
  content: '\33';
}

.icon-next:before {
  content: '\34';
}

.icon-prev:before {
  content: '\35';
}

.icon-book:before {
  content: '\36';
}

.icon-eye-none:before {
  content: '\37';
}

.icon-eye:before {
  content: '\38';
}

.icon-book:before {
  content: '\36';
}

.icon-circle-check:before {
  content: '\39';
}

.icon-delete:before {
  content: '\21';
}

.icon-edits:before {
  content: '\2a';
}

.icon-check:before {
  content: '\23';
}

.icon-uncheck:before {
  content: '\24';
}

.icon-attention:before {
  content: '\22';
}
.glyphs.character-mapping {
  margin: 0 0 20px 0;
  padding: 20px 0 20px 30px;
  color: rgba(0, 0, 0, 0.5);
  border: 1px solid #d8e0e5;
  border-radius: 3px;
}

.glyphs.character-mapping li {
  margin: 0 30px 20px 0;
  display: inline-block;
  width: 90px;
}

.glyphs.character-mapping .icon {
  margin: 10px 0 10px 15px;
  padding: 15px;
  position: relative;
  width: 55px;
  height: 55px;
  color: #162a36 !important;
  overflow: hidden;
  border-radius: 3px;
  font-size: 32px;
}

.glyphs.character-mapping .icon svg {
  fill: #000;
}

.glyphs.character-mapping input {
  margin: 0;
  padding: 5px 0;
  line-height: 12px;
  font-size: 12px;
  display: block;
  width: 100%;
  border: 1px solid #d8e0e5;
  border-radius: 5px;
  text-align: center;
  outline: 0;
}

.glyphs.character-mapping input:focus {
  border: 1px solid #fbde4a;
  -webkit-box-shadow: inset 0 0 3px #fbde4a;
  box-shadow: inset 0 0 3px #fbde4a;
}

.glyphs.character-mapping input:hover {
  -webkit-box-shadow: inset 0 0 3px #fbde4a;
  box-shadow: inset 0 0 3px #fbde4a;
}

.glyphs.css-mapping {
  margin: 0 0 60px 0;
  padding: 30px 0 20px 30px;
  color: rgba(0, 0, 0, 0.5);
  border: 1px solid #d8e0e5;
  border-radius: 3px;
}

.glyphs.css-mapping li {
  margin: 0 30px 20px 0;
  padding: 0;
  display: inline-block;
  overflow: hidden;
}

.glyphs.css-mapping .icon {
  margin: 0;
  margin-right: 10px;
  padding: 13px;
  height: 50px;
  width: 50px;
  color: #162a36 !important;
  overflow: hidden;
  float: left;
  font-size: 24px;
}

.glyphs.css-mapping input {
  margin: 0;
  margin-top: 5px;
  padding: 8px;
  line-height: 16px;
  font-size: 16px;
  display: block;
  width: 150px;
  height: 40px;
  border: 1px solid #d8e0e5;
  border-radius: 5px;
  background: #fff;
  outline: 0;
  float: right;
}

.glyphs.css-mapping input:focus {
  border: 1px solid #fbde4a;
  -webkit-box-shadow: inset 0 0 3px #fbde4a;
  box-shadow: inset 0 0 3px #fbde4a;
}

.glyphs.css-mapping input:hover {
  -webkit-box-shadow: inset 0 0 3px #fbde4a;
  box-shadow: inset 0 0 3px #fbde4a;
}

.heading1 {
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
}

.heading3 {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
}

.heading4 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.body {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.body-large {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.body-small {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
