@import 'variables';


.calendar-container {
  .rbc-month-header{
    height: 32px;
    .rbc-header {
      border-left: none;
      background: #F4F9F9;
      align-items: center;
      letter-spacing: 1px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      font-family: $font-family-sans;
      display: flex;
      text-transform: uppercase;
    }
  }
  .rbc-row-segment {
    padding: 6px 4px 0px 4px;
  }
    
  .container-lg{
    max-width: 1640px;
    padding-right: 47px;
    padding-left: 47px;
    div {
      margin: unset;
    }
  }
}