.timeline__container {
  div {
    margin: unset;
  }
  .timeline {
    display: flex;
    flex-direction: column;

    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 25px;
      margin: 10px 25px 0;
    }
  }
}

.gantt-view__container {
  div, span {
    margin: unset;
  }

  .gantt-view {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    &__legend {
      width: 25%;

      &__container {
        padding-left: 25px;
        padding-right: 25px;
      }

      &__title-box {
        border: 1px solid #E6EBED;
        padding: 12px;
      }

      &__main-box {
        border: 1px solid #E6EBED;
        padding-top: 39px;
        padding-left: 10px;
        padding-right: 10px;
        .ant-collapse-header {
          padding-top:0px;
          padding-bottom:0px;
          height: 50px;
          align-items: center;
        }
        .ant-collapse-content-box {
          padding-top: 0px !important;
          padding-bottom: 0px !important;
        }
        &__course {
          padding-bottom: 25px;

          &__item {
            display: flex;
            align-items: center;

            &__lesson {
              height: 50px;
              margin-left: 1.5rem;
            }

            &__color-box {
              width: 12px;
              height: 12px;
              margin-right: .5rem;
              border: 0.5px solid rgba(0, 0, 0, 0.2);
              border-radius: 2px;
            }
          }
        }
      }

      &__course-title {
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 20px;
      }

      &__lesson-title {
        font-style: normal;
        font-weight: 400;
        font-size: .75rem;
        line-height: 120%;
      }
    }

    &__gantt-container {
      flex: 1;
      overflow: auto;
    }
  }
}
