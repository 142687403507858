@import '../../../assets/scss/_variables.scss';

.summaryContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: relative;
}

.superiorContent {
  margin: 0;

  img {
    margin: 0;
    min-width: 100px;
    position: relative;
    left: -10px;
    margin-bottom: 20px;
  }
}

.logoContainer {
  position: absolute;
  left: -32px;
  bottom: -32px;
}
