@import '../../../assets/scss/variables';

.planCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  max-width: 33.33%;
  box-sizing: border-box;
  max-height: 560px;
  flex-basis: 267px;
  height: 560px;
}

.heading {
  h4 {
    color: $text-secondary;
    text-align: center;
    margin-bottom: 4px;
  }

  label {
    color: $text-secondary;
    text-align: center;
  }

  p {
    color: $text-secondary;
    text-align: center;
    margin-bottom: 0;
    margin-top: 24px;
  }
}

.costContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  h1 {
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
    margin: 0;
  }

  div {
    margin: auto 0;

    label {
      width: fit-content;
      text-align: left;
      margin: 0;
    }
  }
}

.priceText {
  max-width: 100px;
}
