@import '../../../assets/scss/variables.scss';

.alertContainer {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  margin-bottom: 32px;
  background-color: $contrast-1;
  padding: 12px;
  border-radius: 4px;
  border-right: 4px solid transparent;

  i {
    margin: 0;
    color: $secondary;
  }
}

.textsContainer {
  margin: 0 0 0 10px;
}

.alertTitle {
  display: block;
  margin: 0 0 4px 0;
  font-size: 16px;
  line-height: 24px;
  color: $primary-dark;
}

.alertText {
  margin: 0;
  color: rgba($text-secondary, .7);
  font-size: 14px;
  line-height: 20px;
}
