@import '../../../assets/scss/_variables';

.horizontalCard {
  background-color: $contrast-1;
  padding: 24px;
  border-left: 24px solid $primary-light;
  border-radius: 5px;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
