@import '../../../assets/scss/variables.scss';
.cardContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.currentPlan {
  width: 50%;
  text-align: left;
}

.currentPlanLabel {
  color: $text-secondary;
}

.currentPlanValue {
  color: $primary;
  margin-bottom: 0;
}

.content {
  width: 50%;
}
