.alignToRight {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-left: 'auto';
  margin-right: 0;
}

.controlsGroup {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.emailError {
  color: '#b91c1c';
  text-decoration: 'underline';
}
