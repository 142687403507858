@import '../../../assets/scss/_variables.scss';

.verticalCardContainer {
  background-color: $contrast-2;
  padding: 32px;
  padding-top: 60px;
  border: 1px solid $primary-light;
  border-top: 24px solid $primary-light;
  border-radius: 8px;
  overflow: hidden;
}

.active {
  background-color: $contrast-1;
  border-top: 24px solid $primary;
}
