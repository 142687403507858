@import '../../../assets/scss/variables';

.planCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  max-width: 33.33%;
  box-sizing: border-box;
  max-height: 560px;
  flex-basis: 267px;
  height: 560px;
}

.heading {
  h4 {
    color: $text-secondary;
    text-align: center;
    margin-bottom: 4px;
  }

  label {
    color: $text-secondary;
    text-align: center;
  }

  p {
    color: $text-secondary;
    text-align: center;
    margin-bottom: 0;
    margin-top: 24px;
  }
}

.costContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  h1 {
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
    margin: 0;
  }

  div {
    margin: auto 0;

    label {
      width: fit-content;
      text-align: left;
      margin: 0;
    }
  }
}

.priceText {
  max-width: 100px;
}

.sponsorSection {
  width: 100%;
  padding: 5px;
  background-color: $contrast-1;

  .question {
    margin-bottom: 0;

    i {
      margin-right: 10px;
      color: $secondary;
    }
  }

  .clickHereButton {
    margin-bottom: 0;
    color: $secondary;
    font-weight: 700;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.customTooltip {
  max-width: 450px;
  color: $primary;
  border: 1px solid $secondary;
  border-radius: 5px;
  background-color: $contrast-2;
  padding: 5px;
}
