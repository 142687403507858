.contactUsForm {
  max-width: 100%;
  margin: 150px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 300px);
}

.header {
  width: 100%;
  margin: 0px 150px 0px 150px;
}
.subheader {
  width: 100%;
  color: rgba(16.49, 35.07, 40.37, 0.7);
  margin: 0 0 32px 0;
}

.formRow {
  display: flex;
  justify-content: space-between;

  .formColumn {
    width: calc(50% - 10px);

    &:first-child {
      margin-right: 20px;
    }
  }
}

.form {
  width: 100%;
}

input[type='text'],
select {
  background: transparent;
  padding: 12px;
  border: 1px solid var(--Basic-Gray-2, #e6ebed);
  border-radius: 4px;
  margin-bottom: 20px;
}

input[type='text']::placeholder,
select::placeholder {
  color: rgba(16, 35, 40, 0.3);
}

.error {
  color: #d32f2f;
  font-size: 0.875rem;
  margin-top: -20px;
}

.label {
  color: rgba(16, 35, 40, 0.7);
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.select {
  color: var(--Text-Disabled, rgba(16, 35, 40, 0.3));
  font-weight: 400;
  background-image: url('../../../assets/images/arrow-down.svg');
  background-repeat: no-repeat;
  background-position: right 12px center;

  &.placeholderOption {
    color: rgba(16, 35, 40, 0.3);
  }
}

.selectError {
  border-color: #f32f2f;
}

.formActions {
  display: flex;
  justify-content: flex-end;
  padding-top: 16px;

  .backButton {
    margin-right: 12px;
  }
}
.submitButton {
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  background-color: #ffb700;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
  cursor: pointer;

  &:hover {
    background-color: #d6b230;
  }
}
