.assignSeatsModal {
    h2 {
        font-family: IBM Plex Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }
}

.dragableArea {
    display: flex;
    padding: 49px 201px 49px 203px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 9px;
    align-self: stretch;
    border-radius: 4px;
    border: 2px dashed #e6ebed;
    background-color: #f4f9f9;
}

.downloadLink {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
    font-family: IBM Plex Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    text-transform: capitalize;
    width: fit-content;

    a {
        text-decoration: none;
        color: #257a8e;
    }

    img {
        width: 20px;
        height: auto;
    }
}