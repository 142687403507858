@import 'variables';

.calendar-container {
  .cust-bg-toolbar {
    .title-bar{
      display: flex;
      margin-top: 24px;
      margin-bottom: 26px;
      .calendar-title{
        flex: 1 1;
        margin-bottom: 0;
      }
      .course-option-wrap{
        display: flex;
        align-items: center;
        .course-select{
          border: 1px solid #F8C341;
          border-radius: 8px;
        }
        .option-select{
          background-color: #F8C341;
          border-radius: 8px;
          margin-left: 12px;
          .ant-select-selection-item {
            div{
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap
            }
          }
        }
      }
    }
    .nav-section{
      display: flex;
      .nav-section-left{
        display: flex;
        flex: 1 1;
        .today-btn{
          border: 1px solid #F36D35;
          border-radius: 4px;
          width: 89px;
          height: 42px;
          color: #F36D35;
          background-color: transparent;
          font-family: $font-family-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
        }
        .year-select{
          width: 105px;
          height: 42px;
          border: 1px solid #257A8E;
          border-radius: 4px;
          min-width: 100px;
          margin-left: 12px;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          font-family: $font-family-serif;
          .ant-select-selection-item{
            color: #257A8E;
          }
          .ant-select-selector {
            padding: 9px 16px;
          }
        }
      }
      .nav-section-right{
        .nav-btn{
          background: none;
          border: none;
          font: inherit;
          cursor: pointer;
          outline: inherit;
        }
        .right-label{
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          margin-right: 21px;
        }
      }
    }
    .legend-container{
      display: flex;
      border: 1px solid #E6EBED;
      border-radius: 4px;
      background: #F4F9F9;
      min-height: 52px;  
      padding-right: 16px;
      margin-bottom: 24px;
      margin-top: 24px;
      flex-wrap: wrap;
      justify-content: flex-start;
      .legend-item{
        display: flex;
        align-items: center;
        flex: 0 1 auto;
        margin: 0.5em;
        white-space: nowrap;
        padding-left: 16px;
        .legend-swatch{
          width: 12px;
          height: 12px;
          border: 0.5px solid rgba(0, 0, 0, 0.2);
          border-radius: 2px;
          margin-right: 12px;
        }
        .legend-label{
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
        }
      }
    }
  }
}
.option-select-dropdown, .course-select-dropdown{
  width: 270px;
}