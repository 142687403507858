/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600;700&family=IBM+Plex+Serif:wght@200;300;400;500;600;700&display=swap');
@import 'gantt-task-react/dist/index.css';
@import '../fontawesome-pro-6.1.2-web/scss/fontawesome.scss';
@import '../fontawesome-pro-6.1.2-web/scss/regular.scss';
@import '../fontawesome-pro-6.1.2-web/scss/solid.scss';
@import '../fontawesome-pro-6.1.2-web/scss/brands.scss';
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css');
/* Import file */
@import './fonts.scss';
@import './_variables';
@import './reset.scss';
@import './model.scss';

@import './custom-end.scss';
@import './main.scss';
@import './style.scss';
@import './responsive.scss';

/* RBCalendar */
@import './rbc-addons.scss';
@import './react-big-cal.scss';
@import './cust_toolbar.scss';

/* Gantt */
@import './gantt.scss';
@import './cust_calendar.scss';

/* CourseView */
@import './course-view.scss';

@import './paper-grader.scss';