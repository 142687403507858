.model {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  &.open {
    opacity: 1;
    overflow-y: auto;
    overflow-x: hidden;
    display: block;
    visibility: visible;
    pointer-events: all;
    .model-contain {
      opacity: 1;
      -webkit-transform: translateY(0px);
      -ms-transform: translateY(0px);
      transform: translateY(0px);
    }
  }
  .model-contain {
    width: 95%;
    max-width: 550px;
    margin: 0 auto;
    min-height: 100px;
    background-color: #fff;
    margin-top: 100px;
    margin-bottom: 30px;
    opacity: 0;
    -webkit-transform: translateY(-100px);
    -ms-transform: translateY(-100px);
    transform: translateY(-100px);
    position: relative;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
    &.one-big {
      max-width: 600px;
    }
    &.alert-cont {
      max-width: 480px;
    }
    &.two-col {
      max-width: 740px;
    }
    &.three-col {
      max-width: 800px;
    }
    &.four-col {
      max-width: 1000px;
    }
    &.fullwidth {
      max-width: 90%;
    }
    &.model-lg {
      max-width: 955px;
      .icon-close,
      .model-close {
        right: -10px;
        top: -10px;
        position: absolute;
        z-index: 9;
      }
      .model-body {
        padding: 0;
      }
    }
    .model-header {
      color: $color-base;
      text-align: center;
      padding: 20px 30px 10px;
      position: relative;
      h1 {
        font-size: 20px;
        display: inline-block;
        font-weight: 500;
        text-transform: none;
        margin-bottom: 0px;
      }
      p {
        text-align: left;
        font-size: $font-size-base-sm;
        color: $color-base;
        margin: 0 auto;
      }
      .icon-close,
      .model-close {
        display: inline-block;
        width: 40px;
        height: 40px;
        border: none;
        line-height: 40px;
        font-size: 12px;
        text-transform: uppercase;
        vertical-align: middle;
        position: absolute;
        right: 8px;
        cursor: pointer;
        top: 8px;
        cursor: pointer;
        img {
          display: inline-block;
          margin: auto;
          line-height: 40px;
          vertical-align: middle;
        }
        &:hover {
          color: $color-link;
        }
      }
    }
    .model-body {
      padding: 15px 30px 20px 30px;
      .form-group {
        > .fitem:last-child {
          margin-bottom: 10px;
        }
      }
      .btn-group {
        &.flex {
          .btn {
            &.btn-full {
              margin: 0 5px;
            }
          }
        }
      }
    }
  }
}
