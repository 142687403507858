@import 'variables';

.dzu-dropzone {
  overflow: auto !important;
}

.paper-grader-container {
  .paper-grader__header {
    margin: 25px;
  }

  #toast {
    visibility: hidden;
    min-width: 250px;
    margin-left: -125px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    right: 10%;
    bottom: 30px;
    font-size: 17px;
    font-family: 'Helvetica', sans-serif;
  }

  #toast.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }

  .example-pdf {
    padding-left: 10px;
  }

  .examples {
    padding: 0 0 10px 10px;
  }
}