.nav {
  display: flex;
  justify-content: space-between;
}

.left {
  display: flex;
  align-items: center;
  margin: 0;
}

.right {
  margin: 0;
}

.pagination {
  display: flex;
  align-items: center;
}

.pageLink {
  text-decoration: none;
  color: #102328b2;
  margin: 0;
}

.pageItem {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  list-style-type: none;
  width: 36px;
  height: 36px;
  padding: 0;
  margin: 0;

  &.active {
    border-radius: 100%;
    background-color: #e1f2f2;
  }

  &.disabled {
    color: gray;
    cursor: not-allowed;
  }
}
