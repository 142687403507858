@import '../../../assets/scss/variables.scss';

.radioButtonStyles {
  border-radius: 32px;
  border: none;
  padding: 8px 24px;
  font-family: $font-family-base;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.46px;
  text-transform: capitalize;
  background-color: $white-bg;
  color: $text-secondary;
  cursor: pointer;
}

.active {
  background-color: $contrast-1;
  color: $primary;
}
