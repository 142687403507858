.subsMembersHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    .subsMembersHeaderColumn {
        margin: 0;
    }

    h1 {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 4px;
    }

    h2 {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
    }

    button {
        width: 172px;
        height: 46px;
        padding: 12px, 24px, 12px, 24px;
        border-radius: 8px;
        gap: 8px;
        background: #f8c341;
        border: 0;
        cursor: 'pointer';
        color: #1a363e;
        font-family: 'IBM Plex Sans';
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        text-transform: capitalize;
    }

    button:hover {
        cursor: pointer;
    }
}