@import '../../../assets/scss/_variables.scss';

.finalMessageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100svh;
  margin: 0 138px;
}

.title {
  width: 100%;
  text-align: left;
}

.subtitle {
  width: 100%;
  text-align: left;
  color: $secondary;
}

.content {
  white-space: pre-line;
  color: $text-secondary;
  margin-bottom: 24px;
}

.buttonContainer {
  margin: 0;
}

.button {
  width: auto;
}
