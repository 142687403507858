.leave-balance-info-cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
  .lbcard {
    margin: 0;
    margin-right: 30px;
    background: $white;
    padding: 30px 40px 30px 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border: 2px solid $input-bg;
    border-radius: 4px;
    min-width: 270px;
    figure {
      margin-right: 30px;
    }
    h5 {
      margin-bottom: 5px;
      line-height: 24px;
    }
    p {
      margin-bottom: 0;
      line-height: 1;
    }
  }
}
.empty-state-container {
  text-align: center;
  padding: 30px;
  .es-icon {
    img {
      vertical-align: middle;
    }
  }
  .es-text {
    margin-top: 10px;
  }
  .es-action {
    margin-top: 25px;
  }
}
.colored-lable {
  position: relative;
  display: inline-block;
  line-height: 1;
  padding: 4px 10px;
  border-radius: 3px;
  font-size: 11px;
  font-weight: bold;
  margin-right: 4px;
  &.orange-lable {
    background: rgba(224, 159, 30, 0.15);
    color: #d28d04;
  }
  &.green-lable {
    background: #dff4f0;
    color: #0a9a75;
  }
  &.red-lable {
    background: rgba(255, 51, 51, 0.1);
    color: #ff3333;
  }
  &.gray-lable {
    background: #eeeeee;
    color: #333333;
  }
  &.no-bg {
    background: transparent !important;
  }
}

.f-2col-item {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.f-2col-item > * {
  margin: 0;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.owl-dt-inline-container {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: $input-bg;
  border-radius: 5px;
  div {
    margin: 0;
  }

  .owl-dt-control-button {
    background: rgba(0, 0, 0, 0) !important;
    border: none !important;
    padding: 0;
    font-size: 16px;
    line-height: 22px;
    color: #062640;

    &:hover {
      color: $primary;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
  .owl-dt-container-info {
    display: none;
  }
  .owl-dt-container-row {
    border-bottom: none;
  }
}
.owl-dt-inline {
  display: block;
  width: 100%;

  .owl-dt-inline-container {
    width: 100%;
  }
}
.form-inc-dec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  button {
    background: $input-bg !important;
    border: 0 !important;
    height: 36px;
    font-size: 14px;
    width: 24px;
    cursor: pointer;
    outline: none;
    box-shadow: none !important;
    border-radius: 0;
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
    text-align: center;
    line-height: 36px;
    color: inherit !important;
    padding: 0;
    transition: none;
    outline: none !important;

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  input {
    background: #f7f9fb;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    width: 60px;
    border-radius: 0;
  }
}

// Add Holiday Modal Section
.holiday-wrap {
  display: flex;
  .holiday-form-wrap {
    display: flex;
    form {
      margin: 0;
      width: 100%;
      .holiday-header {
        width: 100%;
        display: inline-flex;
        margin-bottom: 24px;
        border-bottom: 1px solid #E6EBED;
        .holiday-header-item {
          text-align: left;
          margin-left: 0px;
          margin-right: 12px;
          width: 290px;
          label {
            margin-bottom: 12px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
      .holiday-list {
        display: inline-block;
        width: 100%;
        .holiday-item {
          width: 100%;
          display: inline-flex;
          margin-bottom: 5px;
          input[type=checkbox] {
            margin-top: 0px;
          }
          label {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
          }
          .holiday-item-name {
            margin-left: 12px;
            margin-right: 12px;
            width: 245px;
          }
          .holiday-item-date {
            margin-left: 12px;
            margin-right: 12px;
            width: 375px;
          }
        }
        .add-holiday-option {
          margin-top: 25px;
          &.display-true {
            display: inline-block !important;
          }
          &.display-false {
            display: none !important;
          }
          label {
            font-weight: 700;
            font-size: 16px;
            line-height: 35px;
            color: #F36D35;
            cursor: pointer;
          }
        }
        .add-holiday-item {
          padding-top: 24px;
          margin-top: 24px;
          border-top: 1px solid #E6EBED;
          &.display-true {
            display: block !important;
          }
          &.display-false {
            display: none !important;
          }
          .add-holiday-header {
            font-weight: 600;
            font-size: 20px;
            line-height: 32px;
            padding-bottom: 20px;
          }
          .add-holiday-body {
            .add-holiday-body-items {
              width: 100%;
              display: flex;
              justify-content: space-between;
              .inner-items-wrap {
                margin-left: 0;
                margin-right: 0px;
                &.start-date {
                  width: 170px;
                }
                &.end-date {
                  width: 170px;
                }
                &.add-holiday-btn {
                  margin-top: auto;
                  margin-right: 0px;
                  button {
                    background-color: #257A8E;
                    color: #fff;
                    width: 107px;
                  }
                }
              }
              label {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
}

.ant-modal-footer {
  .add-holiday-footer {
    border-top: 1px solid #E6EBED;
    padding-top: 24px;
    .holiday-cancel-btn {
      background-color: #fff;
      color: #000;
    }
  }
}
// End Add Holiday Modal Section

// Add Error Modal Section
.calendar-error-modal {
  width: 817px !important;
  .ant-modal-body {
    .modal-head {
      padding-bottom: 20px;
      h1 {
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        color: #102328;
        padding-bottom: 20px;
      }
      h4 {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #102328;
      }
    }
    .fitem-check {
      label.error-checkbox-label {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #1A363E;
        display: inline-block;
        padding-left: 12px;
      }
    }
  }
  .ant-modal-footer {
    margin-top: 10px;
    border-top: 1px solid #E6EBED;
    padding-top: 24px;
    button.ant-btn {
      background-color: #F8C341;
      border: none;
      &:hover {
        background-color: #F8C341;
        color: #193E47;
      }
    }
  }
}
// End Add Error Modal Section

// Add Export Modal Section
.export-wrap {
  padding: 0px 26px 20px;
  .modal-head {
    padding-bottom: 50px;
    h1 {
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      color: #102328;
      padding-bottom: 20px;
    }
    h4.h4 {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #102328;
    }
  }
  .export-item {
    width: 100%;
    display: inline-flex;
    margin-bottom: 25px;
    input[type=checkbox] {
      margin-top: 0px;
    }
    .export-item-name {
      margin-left: 12px;
      label {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

.export-modal {
  .ant-modal-footer {
    border-top: 1px solid #E6EBED;
    .export-modal-footer {
      padding-top: 24px;
      .export-cancel-btn {
        background-color: #fff;
        color: #000;
      }
    }
  }
}