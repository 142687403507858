@import '../../../assets/scss/variables';

.sponsoredSubscriptionFormContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  min-height: 100svh;
  margin: 0 138px;
}

.header {
  width: 100%;
}

.subheader {
  width: 100%;
  color: $text-secondary;
  margin-bottom: 32px;
}

.sponsorSection {
  display: flex;
  align-items: baseline;
  margin-bottom: 32px;
  background-color: $contrast-1;
  padding: 12px;

  p {
    margin-bottom: 0;
  }

  i {
    margin-right: 10px;
    color: $secondary;
  }
}
.generateLinkText {
  width: 100%;
  color: $text-secondary;
  text-align: left;
  margin-bottom: 12px;
}

.inputContainer {
  width: 100%;

  .inputName {
    width: 100%;
    margin-bottom: 12px;

    input {
      margin-bottom: 0;
    }
  }
}

.generateLinkSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  .inputContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 16px;

    input {
      margin-bottom: 0;
    }

    .inputLink {
      width: 100%;

      input {
        margin-bottom: 5px;
      }

      .inputError {
        color: $error-color;
      }
    }

    button {
      height: 46px;
      white-space: nowrap;
      padding: 11px 32px;

      i {
        margin-left: 5px;
      }
    }
  }
}

.dividerContainer {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

.customDivider {
  height: 1px;
  background-color: $border-disabled;
  width: 50%;
  margin: 0 auto;
}

.orText {
  margin: 0 10px;
  color: $text-disabled;
}
