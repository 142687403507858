@import '../../../assets/scss/_variables.scss';

.pricesContainer {
  display: flex;
  flex-direction: column;
}

.productList {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.productItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.productName {
  text-align: left;

  color: $white;
  width: 70%;
}

.productPrice {
  text-align: right;
  color: $white;
  width: 30%;
}

.subTotalItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 12px 0;
  border-bottom: 1px solid $white-10;
}

.totalItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-top: 12px;

  label {
    font-weight: 700;
  }
}

.priceClarification {
  width: 100%;
  text-align: right;
  color: $white;
  padding-bottom: 12px;
}

.individualSub {
  text-align: left;
  color: $white;
  width: 100%;
  margin-top: -22px;
  margin-bottom: 32px;
}

.noProducts {
  color: $white;
}
