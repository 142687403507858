@import '../../assets/scss/variables';

.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.asideContent {
  width: 33.33%;
  background-color: $primary-dark;
  min-height: 100svh;
  padding: 32px;
}

.content {
  width: 66.66%;
}
