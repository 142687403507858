@charset "utf-8";

/* CSS Document */

/* Table of Contents
---------------------------------------------------
	1. Mobile
	2. iPad
	3. desktop
	4. Other
*/

/* 1. Mobile and ipad
---------------------------------------------------*/
.right-nav i.mydropbtn {
  display: none;
}

@media only screen and (max-width: 1023px) {
  /* Typography */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 15px;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 19px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 17px;
  }

  h6 {
    font-size: 16px;
  }

  /* 1.1 Mobile
---------------------------------------------------*/
}

#mobile-menu {
  display: none;
}

@media (max-width: 767px) {
  #content-area {
    padding-top: 60px;
  }

  .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  p {
    font-size: 13px;
  }

  blockquote p {
    font-size: 13px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 15px;
  }

  h1 {
    font-size: 22px;
  }

  h2 {
    font-size: 19px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 17px;
  }

  h5 {
    font-size: 16px;
  }

  h6 {
    font-size: 15px;
  }

  .btn,
  button[type='button'],
  button[type='submit'],
  .submit,
  input[type='submit'],
  input[type='button'],
  input[type='reset'] {
    padding: 5px 12px;
    font-size: 11px;

    &.sm-btn {
      padding: 3px 10px;
      font-size: 11px;
    }
  }

  .form-group {
    .fitem {
      margin-bottom: 20px;
    }

    .half-fitem {
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      -o-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-bottom: 0;

      .half-fitem {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
        margin-bottom: 20px;

        .half-fitem {
          margin-bottom: 0;
        }
      }
    }
  }

  /* 2. iPad
---------------------------------------------------*/
}

@media (min-width: 768px) and (max-width: 1023px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  /* 3. desktop
---------------------------------------------------*/
}

@media (min-width: 1024px) and (max-width: 1030px) {
  .flex-table,
  .flex-table.leave-status {
    .titem-row {
      .titem {
        /* Common talbe */
        &.ti-no {
          width: 5%;
        }

        &.ti-reason {
          width: 35%;
        }

        &.ti-type {
          width: 13%;
        }

        &.ti-date {
          width: 13%;
        }

        &.ti-status {
          width: 13%;
        }

        &.ti-finalstatus {
          width: 13%;
        }

        &.ti-cancel {
          width: 8%;
        }

        &.ti-ltype {
          width: 10%;
        }

        &.ti-atte {
          width: 18%;
        }

        &.ti-in {
          width: 12%;
        }

        &.ti-in-out {
          width: 18%;
        }

        &.ti-out {
          width: 12%;
        }

        font-size: 12px;
      }

      &.titem-title-row {
        .titem {
          font-size: 12px;
        }
      }
    }
  }
}

@media (max-width: 479px) {
}
