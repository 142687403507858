.fc-cal-wrap {
  background: $white;
  box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.landing-page {
  background-image: url('../images/main-bg.png');
  display: block;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  color: $white;
  padding: 80px 0;
  min-height: 100vh;
  align-items: center;
  display: flex;
  justify-content: center;

  .container {
    max-width: 600px;
  }

  .title-row {
    border-bottom: 1px solid $white;
    padding-bottom: 10px;
    margin-bottom: 20px;

    h1 {
      color: $brand-color;
      position: relative;
      text-transform: none;
      font-size: 45px;
      line-height: normal;
      font-weight: 400;
      margin: 0 auto 20px;
    }

    p {
      font-size: 22px;
      font-weight: 600;
      color: inherit;
      margin: 0 auto;
    }
  }

  .caption-area {
    p {
      font-size: 22px;
      color: inherit;
    }

    ol {
      li {
        color: inherit;

        &:before {
          display: none;
        }
      }
    }
  }
}

.steper-wrap {
  display: flex;
  width: 100%;
  align-items: center;

  .buildlogo {
    max-width: 260px;
    margin: 0;

    a {
      display: block;
    }
  }

  .steps-list {
    flex: 1;
    margin: 0;
    display: flex;

    li {
      flex: 1;
    }
  }

  .btn-wrap {
    padding: 0 15px;

    .btn-txt {
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      text-decoration: underline;
      color: #1a5563;
    }
  }
}

.steps-list {
  li {
    display: block;
    text-align: center;
    position: relative;
    border-bottom: 1px solid #758b91;
    padding-bottom: 15px;

    &:before {
      width: 20px;
      height: 20px;
      background: #ddd;
      content: '';
      position: absolute;
      right: 0;
      top: 5px;
      border-radius: 50px;
      border: 1px solid #758b91;
      margin-left: -10px;
      text-align: right;
    }

    a {
      text-transform: uppercase;
      position: absolute;
      width: 100%;
      text-align: center;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.05em;
      color: #1a5563;
      left: 50%;
      top: -20px;
    }
  }
}

.auth-block {
  display: flex;
  align-items: stretch;
  width: 100%;

  .right-caption {
    flex: 1;
    width: 70%;
  }

  .logo-aside {
    min-height: 100vh;
    max-width: 490px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    background: url('../images/signup-frame.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    figure {
      margin: 0;
      padding: 40px;
    }
  }
}

.auth-block {
  .title-row {
    h1 {
      font-weight: 700;
      font-size: 45px;
    }
  }

  .caption-area {
    padding: 150px 0 80px;
    max-height: 100vh;
    overflow: auto;

    .container {
      max-width: 690px;
    }
  }
}

.block-row {
  padding: 30px 0;

  &.pt-80 {
    padding-top: 80px;
  }
}

.page-profile {
  &__current-plan-children {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap;

    h1 {
      margin: 0;
      margin-right: 12px;
      color: $secondary;
    }

    label {
      width: 100px;
      margin: 0;
      margin-right: 32px;
      color: $text-secondary;
    }

    button {
      display: flex;
      gap: 10px;
      padding: 12px 24px;
    }
  }
}

.main-head {
  background: url(../images/header-bg.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 40px 0;
  border-bottom: 1px solid #dddedd;

  .header-top {
    .profile-wrap {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      figure {
        width: 65px;
        height: 65px;
        overflow: hidden;
        border-radius: 80px;
        margin: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      h2 {
        font-size: 32px;
        font-weight: 400;
        line-height: 40px;
        margin: 0;
        color: $secondary;
        margin: 0;
        padding-left: 16px;
        width: 410px;

        strong {
          font-weight: 700;
        }
      }

      .social-share {
        width: 500px;
        text-align: right;

        button {
          padding-right: 5px !important;
        }
      }
    }
  }

  &.inner-head {
    background: #cde6e6;
    border-bottom: 1px solid $primary;

    h1 {
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      color: $secondary;
      margin: 0 auto;
    }
  }

  &.page-tabs {
    padding-bottom: 70px;
  }
}

.header-bottom {
  width: 100%;
  display: block;
  padding-top: 25px;
  padding-bottom: 20px;

  .slick-dots {
    bottom: -25px;
  }
}

.card {
  background: #fff;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1), 0px 4px 6px -4px rgba(16, 24, 40, 0.1);
  border-radius: 8px;
  padding: 25px 25px 10px;

  &.card-info {
    background: #f2f8f9;
    border: 1px solid $secondary;
  }

  .card-body {
    h4 {
      color: $secondary;
      margin: 0 auto 8px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .lbl {
        margin: 0;
        color: #767979;
        background: #efefef;
        border-radius: 63px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        padding: 5px 10px;
      }
    }

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 8px;
    }
  }
}

// DASHBOARD PAGE

.page-heading {
  h1 {
    font-size: 26px;
    font-weight: 600;
    line-height: 32px;
    color: $secondary;
  }
}

.cource-list {
  [data-react-beautiful-dnd-drag-handle] {
    margin: 0 10px 20px;
    width: 33.33%;
    max-width: calc(100% / 3 - 14px);

    &:last-child {
      margin-right: 0;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  // display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;

  .cource-item {
    margin: 0;
    position: relative;
    background: linear-gradient(135deg, #193e47 0%, #257a8e 100%);
    box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1), 0px 4px 6px -4px rgba(16, 24, 40, 0.1);
    height: 238px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;

    &.clr-light {
      background: linear-gradient(135deg, #257a8e 0%, #81c0c1 100%);
    }

    &.clr-dark {
      background: linear-gradient(135deg, #3e143f 0%, #8b728c 100%);
    }

    &.clr-blank {
      background: linear-gradient(135deg, #193e47 0%, #257a8e 100%);
    }

    &.add-new-item {
      cursor: pointer;
      background: transparent;
      border: 1px dashed #989b9a;
      box-shadow: none;

      &:before {
        display: none;
      }

      .cource-head {
        i {
          color: #989b9a;
          font-size: 30px;
        }
      }

      .cource-caps {
        h5 {
          color: $primary;
        }

        p {
          color: $primary;

          i {
            color: $secondary;
          }
        }
      }
    }

    &:before {
      position: absolute;
      content: '';
      background-image: url('../images/cource-item-bg.svg');
      max-width: 197px;
      max-height: 197px;
      right: 0;
      top: 0;
      height: 70%;
      width: 70%;
      background-repeat: no-repeat;
      background-position: top right;
      pointer-events: none;
    }

    .cource-head {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      padding: 25px;

      i {
        font-size: 34px;
        color: $brand-color;
        margin: 0;
        display: inline-block;
        vertical-align: top;
        line-height: 34px;
      }

      .icon-grip-dots-vertical {
        font-size: 30px;
        color: $white;
      }
    }

    .cource-caps {
      width: 100%;
      width: 100%;
      padding: 25px;
      margin: 0;

      h5 {
        color: $white;
        margin: 0 auto 10px;
      }

      a {
        color: $white;
        display: inline-block;
        vertical-align: top;

        i {
          color: $brand-color;
          display: inline-block;
          vertical-align: middle;
          padding-right: 10px;
          line-height: 16px;
        }
      }

      p {
        margin: 0;
        display: block;

        i {
          padding-right: 10px;
          line-height: 16px;
        }
      }
    }
  }
}

.weekday-wrap {
  align-items: stretch;
  margin: 0;
  padding: 10px 0 20px;

  li {
    margin: 0;
    flex: 1;
    background: linear-gradient(180deg, #ffffff 44.64%, #bcc7ca 117.86%);
    border: 1px solid #a3b2b5;
    display: block;
    text-align: center;
    padding: 13px 10px;
    color: $primary;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    margin-right: -1px;
    transition: all 0.3s ease;
    cursor: pointer;

    &:first-child {
      border-radius: 8px 0px 0px 8px;
    }

    &:last-child {
      border-radius: 0 8px 8px 0px;
    }

    &.active {
      background: #fbdb8d;
      box-shadow: inset 0px 5px 6px rgba(0, 0, 0, 0.15);
      font-weight: 700;
    }

    &:hover {}
  }
}

.weekly-wrap {
  .maxw-276 {
    max-width: 276px !important;

    +.maxw-276 {
      margin-left: auto;
    }
  }

  &.form-group {
    .half-fitem {
      .half-fitem {
        width: 100%;
        max-width: none;

        &.half-half-fitem {
          &.last {
            max-width: 115px;
            margin-left: 10px;
          }
        }

        &.fitem {
          display: flex;

          * {
            margin-left: 0;
          }
        }
      }
    }
  }

  >.fitem {
    margin-bottom: 15px;

    &.half-fitem {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .days-item {
    min-width: 100px;
    text-align: left;
    margin: 0;
    padding-right: 13px;

    p {
      margin: 0;
      color: $primary;
      font-weight: 700;
      font-size: 16px;
    }

    label {
      color: $primary;
      font-weight: 700;
      font-size: 16px;
      background: #fbdb8d;
      padding: 13px 10px;
      border-radius: 8px;
      text-align: center;
      line-height: 22px;
      margin: 0;
    }

    +.fitem {
      padding-right: 10px;
    }
  }

  select {
    min-width: auto;
    padding-right: 48px;
  }
}

.list-item {
  border-radius: 8px;
  background: #fef2f2;
  border: 1px solid #b91c1c;
  padding: 15px;
  margin: 0 0 20px;

  &.danger-list {
    background: #fef2f2;
    border: 1px solid $error-color;

    p {
      line-height: 32px;
    }
  }

  &.warn-list {
    background: #fce7b3;
    border: 1px solid #fce7b3;

    li {
      font-weight: 400;

      i {
        color: #257a8e;
      }
    }
  }

  li {
    display: block;
    position: relative;
    padding: 0 0 0 30px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 8px;
    color: $primary;

    &:last-child {
      margin-bottom: 0;
    }

    i {
      color: $error-color;
      padding-right: 5px;
      position: absolute;
      left: 0;
      top: 2px;
      font-size: 20px;
    }
  }
}

.container-lg {
  max-width: 1640px;
  padding-right: 25px;
  padding-left: 25px;
}

.page-head {
  padding: 25px 25px 0;
  margin: 0;
  box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
  background: $white;

  .course-details-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    border-top: 1px solid #ddd;

    .course-view-tab {
      margin: 0;
      display: flex;

      .ant-tabs-tab {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 26px;
        color: rgba(16, 35, 40, 0.7);

        &.ant-tabs-tab-active {
          color: #102328;
        }
      }

      .ant-tabs-ink-bar {
        background-color: rgba(243, 109, 53, 1);
      }
    }

    .course-details {
      margin: 0;
      display: flex;

      p {
        margin: 0;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        padding-right: 20px;

        strong {
          font-weight: 700;
          padding-right: 5px;
        }

        span {
          font-weight: 400;
        }
      }
    }
  }

  .course-year {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 10px;

    p {
      margin: 0;
      position: relative;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;

      &:after {
        content: '|';
        padding: 0 10px;
        color: #ddd;
        display: inline-block;
        vertical-align: middle;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }

  .course-time {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 10px;

    p {
      margin: 0;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      padding-right: 20px;
      display: flex;

      &:first-child {
        min-width: 435px;
      }

      i {
        padding-right: 5px;
        display: flex;
        align-items: center;
        margin-left: 0;
        margin-right: 0;
      }

      span {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  .head-inner {
    margin: 0;
    justify-content: space-between;
    align-items: flex-start;

    h1 {
      font-size: 32px;
      color: $secondary;
      font-weight: 700;
      margin: 0 auto 10px;
    }

    .head-left {
      margin: 0;
      flex: 1;
    }

    .head-right {
      margin: 0;

      .mydropdowns,
      select {
        min-width: 180px;
      }
    }
  }
}

.course-empty {
  align-items: stretch;
  margin-bottom: 20px;

  .card {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;

    &.bg-cardimg {
      background: url('../images/first-coursebg.png');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }

    .cource-head {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      padding: 25px;

      i {
        font-size: 34px;
        color: $secondary;
        margin: 0;
        display: inline-block;
        vertical-align: top;
        line-height: 34px;
      }

      .icon-grip-dots-vertical {
        font-size: 30px;
        color: $white;
      }
    }

    .cource-caps {
      width: 100%;
      width: 100%;
      padding: 25px;
      margin: 0;

      h4 {
        color: $white;
        margin: 0 auto 10px;
        font-family: $font-family-sans;
      }

      a {
        display: inline-block;
        vertical-align: top;
      }

      p {
        display: block;
      }
    }
  }

  .card-lg {
    flex: 1;
    background: $secondary;
    color: $white;
    padding: 0;

    .cource-head {
      padding-bottom: 0;

      i {
        color: $brand-color;
        font-size: 36px;
      }
    }

    h4 {
      margin-bottom: 10px;
    }

    p {
      margin: 0 0 20px;
      display: block;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: $white;
    }
  }

  .card-info {
    max-width: 310px;
    margin-left: 20px;
    padding: 0;

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      margin: 0;
    }
  }
}

.head-tab {
  width: 100%;
  margin-top: -43px;

  .ant-tabs-nav {
    margin-bottom: 40px;

    .ant-tabs-ink-bar {
      display: none;
    }

    .ant-tabs-tab {
      padding: 5px 5px;
      border-radius: 8px 8px 0px 0px;
      min-width: 240px;
      text-align: center;
      border-bottom: 1px solid $primary;
      margin: 0;

      &.ant-tabs-tab-active {
        background: $secondary;
        border-bottom: none;
        transition: all 0.3s ease;
        border-radius: 8px 8px 0px 0px;

        .ant-tabs-tab-btn {
          font-weight: 700;
          color: $white;
        }
      }

      .ant-tabs-tab-btn {
        font-weight: 400;
        font-size: 22px;
        line-height: 32px;
        color: $primary;
      }
    }
  }
}

.details-list {
  padding-bottom: 20px;

  label {
    font-weight: 500;
    font-size: 18px;

    &.lbl-txt {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      margin-bottom: 5px;
    }

    &.disable {
      color: #767979;
      font-weight: 500;
    }
  }
}

.mprofile-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dddedd;
  margin: 0 auto 35px;
  padding-bottom: 30px;
  text-align: center;

  .mprofile-inner {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: auto 0;

    .edit-icon {
      position: absolute;
      bottom: 0px;
      right: 0px;
      display: inline-block;
      background: #d3e4e8;
      border: none;
      color: $secondary;
      width: 32px;
      height: 32px;
      padding: 0;
      text-align: center;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 18px;
        line-height: 18px;
        margin: 0;
      }
    }

    figure {
      margin: 0 auto;
      width: 130px;
      height: 130px;
      display: block;
      overflow: hidden;
      border-radius: 150px;

      img {
        display: block;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .edit-button {
    margin: auto 0;
    position: relative;
    right: 1px;
  }
}

.default-profile-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;

  li {
    cursor: pointer;
    max-width: calc(100% / 3 - 15px);
    margin: 0 10px 20px;
    display: block;
    padding: 0;
    width: 33.33%;
    position: relative;

    &:nth-child(3n-0) {
      margin-right: 0;
    }

    &:nth-child(3n + 1) {
      margin-left: 0;
    }

    &.selected {
      &:before {
        width: 48px;
        height: 48px;
        color: $white;
        background: $secondary;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        border-radius: 40px;
        bottom: 0;
        right: 0;
        font-weight: 700 !important;
      }

      figure {
        border-color: $secondary;

        &:before {
          position: absolute;
          content: '';
        }
      }

      figure {
        width: 200px;
        height: 200px;
        border-radius: 150px;
        overflow: hidden;
        border: 5px solid $secondary;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.modal-body {
  .ant-tabs-nav {
    display: block;

    .ant-tabs-ink-bar-animated {
      display: none;
    }

    .ant-tabs-nav-list {
      display: flex;

      .ant-tabs-tab {
        border-bottom: 1px solid #dddedd;
        margin: 0 auto;
        flex: 1;
        text-align: center;

        .ant-tabs-tab-btn {
          font-weight: 400;
          font-size: 22px;
          line-height: 32px;
          color: #193e47;
        }

        &.ant-tabs-tab-active {
          background: #e6f2f3;
          border-bottom: 1px solid #bbbcbc;
          border-radius: 8px 8px 0px 0px;

          .ant-tabs-tab-btn {
            font-weight: 700;
            font-size: 22px;
            line-height: 32px;
          }
        }
      }
    }
  }
}

.ant-upload.ant-upload-drag {
  padding-bottom: 10px;
  cursor: pointer;

  .ant-upload-drag-icon {
    display: block;
    margin: 0 auto 20px;
  }

  .ant-upload-text {
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 5px;
  }

  .ant-upload-hint {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  text-align: center;
}

.profile-adjust {
  position: relative;
  display: block;
  padding-bottom: 70px;

  .crop-container {
    height: 400px;
    position: relative;
    width: 400px;
  }

  h4 {
    text-align: center;
  }

  .slider {
    padding: 22px 0px;
  }

  .zoom-range {
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 2px;

    // border-top: 5px solid #fff;
    // border-bottom: 5px solid #fff;
    background: #257a8e;
    width: 100%;
  }

  .zoom-range::-moz-range-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #257a8e;
    background: #257a8e;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .zoom-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #257a8e;
    background: #257a8e;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .controls:hover input[type='range']::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
    border-radius: 50%;
  }

  .controls:hover input[type='range']::-moz-range-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  }

  .controls {
    position: absolute;
    bottom: 30px;
    left: 50%;
    width: 55%;
    transform: translateX(-50%);
    height: 5px;
    display: flex;
    align-items: center;

    .icon {
      cursor: pointer;

      &.icon-remove {
        font-size: 22px;
        line-height: 20px;
        margin-right: 10px;
        display: flex;
      }

      &.icon-add {
        font-size: 22px;
        line-height: 20px;
        margin-left: 10px;
        display: flex;
      }
    }
  }
}

.px-2 {
  padding: 0 2px;
}

.lg-txt {
  font-size: 18px;
}

.ant-drawer {
  opacity: 0;

  &.ant-drawer-open {
    opacity: 1;
  }
}

.full-drawer {
  .fd-body {
    display: flex;
    align-items: stretch;
    background: #f8fafc;
    flex: 1;
    width: 100%;

    .fd-left-wrap {
      width: 50%;
      background: #f8fafc;
      padding: 32px;
      position: relative;

      label {
        font-weight: 700;

        &.lbl-txt {
          font-style: normal;
          color: $primary;
          font-size: 18px;
          font-weight: 400;
        }
      }

      .title-row {
        justify-content: space-between;
        margin-bottom: 20px;
        align-items: flex-start;

        .title-cap {
          margin: 0;
          flex: 1;
          margin: 0;
        }

        .right-btn {
          margin: 0;
          padding-left: 20px;

          .btn-txt {
            text-decoration: underline;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;

            .icon {
              margin-right: 5px;
            }

            &:hover {
              text-decoration: none;
            }
          }
        }

        h2 {
          margin-bottom: 8px;
          font-size: 26px;
          color: $secondary;
          font-weight: 700;
          line-height: 32px;
        }

        p {
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          margin: 0;
        }
      }

      &:before {
        position: absolute;
        content: '';
        border-width: 43px 32px;
        border-style: solid;
        right: -47px;
        top: 0;
        border-color: transparent transparent transparent #f8fafc;
        border-radius: 2px;
      }
    }

    .fd-right-wrap {
      width: 50%;
      background: #e6f2f3;
      padding: 32px;

      .title-row {
        margin-bottom: 40px;
      }
    }
  }

  .ant-drawer-body {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  .fd-header {
    width: 100%;
    height: auto;
    background: $primary;
    color: $white;
    padding: 20px 40px;
    align-items: center;
    margin: 0;

    .left-caption {
      text-align: left;
      flex: 1;

      .date-title {
        margin: 0 auto;
        font-weight: 600;
        font-size: 26px;
        line-height: 32px;
        font-family: $font-family-base;
      }

      .back-btn {
        font-size: 18px;
        color: inherit;
        margin: 0;
        font-weight: 500;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }

        .icon {
          margin-right: 5px;
          font-size: 16px;
        }
      }
    }

    h1 {
      font-size: 32px;
      line-height: 40px;
      font-weight: 700;
      flex: 1;
      color: inherit;
      margin: 0;
      text-align: center;
    }

    .right-caption {
      text-align: right;
      flex: 1;

      .btn-group {
        border-right: 1px solid #bbbcbc;
        padding-right: 45px;
        display: inline-block;
        vertical-align: middle;
        margin: 0 auto;
        margin-right: 25px;

        .btn-bdr {
          box-shadow: none;
          margin: 0 10px;

          &:last-child {
            margin-right: 0;
          }

          &:first-child {
            margin-left: 0;
          }

          i {
            margin: 0;
            line-height: 18px;

            &:last-child {
              margin-left: 5px;
            }

            &:first-child {
              margin-right: 5px;
            }
          }

          &.disabled {
            background-color: $btn-gray-bg;
            color: $btn-gray-color;
            border-color: $btn-gray-bg;
          }
        }
      }

      .date-block {
        font-size: 22px;
        font-weight: 600;
        color: inherit;
        margin: 0;
      }

      .ant-closedr {
        position: relative;
        display: inline-block;
        vertical-align: middle;

        .icon-add {
          cursor: pointer;
          background: url('../images/close-modal.svg');
          display: block;
          font-size: 32px;
          height: 20px;
          width: 20px;
          background-size: auto;
          padding: 0;
          background-repeat: no-repeat;
          background-position: center center;

          &:before {
            display: none;
          }
        }
      }
    }
  }

  &.fl-lesson-drawer {
    .fd-right-wrap {
      .title-row {
        align-items: center;

        h4 {
          margin: 0;
        }
      }
    }

    .fd-header {
      padding: 17px 30px;
      background-color: $white;
      color: $primary;
    }

    .ant-drawer-content-wrapper {
      max-width: 1170px;
    }
  }
}

.fd-right-wrap {
  .title-row {
    .right-cap {
      margin: 0;
      max-width: 230px;
      width: 30%;
    }

    .left-cap {
      margin: 0;
      flex: 1;

      h4 {
        font-weight: 700;
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 16px;
      }
    }

    h2 {
      font-weight: 700;
    }
  }
}

.warn-card {
  .progress-wrap {
    background: #b91c1c;

    .progress-plan {
      img {
        display: inline-block;
        vertical-align: middle;
        padding-right: 10px;
      }
    }

    .progress-bar {
      span {
        background: #fcd34d;
      }
    }
  }
}

.progress-wrap {
  background: $primary;
  color: $white;
  padding: 12px 10px;
  border-radius: 8px;

  .progress-bar {
    width: 100%;
    position: relative;
    height: 13px;
    background: #fce7b3;
    border-radius: 80px;
    margin: 8px 0;
    overflow: hidden;

    span {
      width: auto;
      height: 100%;
      top: 0;
      left: 0;
      background: #f36d35;
      display: inline-block;
      vertical-align: top;
    }
  }

  p {
    color: inherit;
    margin: 0 auto;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    &.progress-time {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      span {
        margin: 0;
      }

      strong {
        flex: 1;
        text-align: right;
      }
    }
  }
}

.event-list {
  padding: 0 0 40px;

  .event-card {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
    margin-bottom: 22px;

    &.light-card {
      background: #d3e4e8;

      .event-body {
        padding-top: 0;
      }
    }
  }

  .event-title {
    display: flex;
    padding: 16px;
    border-bottom: 1px solid #dddedd;

    h6 {
      margin: 0;
      flex: 1;
      font-weight: 600;
      line-height: 24px;
      font-family: $font-family-sans;
    }

    .right-cap {
      margin: 0;

      .time {
        font-weight: 400;
        margin: 0;
        font-size: 18px;
        line-height: 24px;

        i {
          display: inline-block;
          margin-right: 8px;

          &.clr-danger {
            color: $danger;
          }
        }

        &.right-bar {
          padding-right: 16px;
          margin-right: 16px;
          position: relative;

          &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            width: 1px;
            height: 25px;
            background-color: #bbbcbc;
          }
        }
      }
    }
  }

  .event-body {
    padding: 16px;

    .event-title {
      h6 {
        color: #47656c;
      }
    }

    .caption-card {
      background: $white;
      padding: 14px 16px;
      border-radius: 8px;
      margin-bottom: 17px;

      p {
        margin: 0;
      }

      .btn-txt {
        font-weight: 700;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .btn-group {
      text-align: right;
      padding: 15px 0;
      margin: 0;

      .btn-txt {
        font-weight: 500;
        text-decoration: underline;
        margin-left: 24px;
        margin-right: 0;
        display: inline-flex;
        align-items: center;

        .icon {
          margin-right: 10px;
          padding-left: 0;
          font-size: inherit;
        }

        &:hover {
          text-decoration: none;
        }

        &:before {
          display: none;
        }
      }
    }
  }

  .event-footer {
    padding: 23px 16px;
    display: flex;
    justify-content: flex-end;
  }
}

.custom-dropdown {
  position: relative;

  .time {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;

    .clr-danger {
      color: $danger;
    }
  }

  .ellipsis-btn {
    color: $secondary;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    display: block;
    border-radius: 4px;

    &.show {
      background-color: $black;
      color: $brand-lightbg;
    }
  }

  .cdropdown-menu {
    display: none;
    position: absolute;
    right: 0;
    top: 100%;
    margin-top: 2px;
    background-color: $white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    min-width: 193px;
    margin: 7px 0 0 0;
    padding: 0;
    border-radius: 4px;
    border: 1px solid $border-base;

    li {
      list-style: none;
      padding: 0;

      +li {
        border-top: 1px solid $border-base;
      }
    }

    a {
      color: inherit;
      display: block;
      padding: 7px 16px;
      font-size: 18px;
      line-height: 24px;

      &:hover {
        font-weight: 600;
        background-color: $brand-lightbg;

        i {
          color: $color-base;
        }
      }

      i {
        margin-right: 16px;
        color: #989b9a;
      }
    }
  }
}

.lesson-plan-drawer {
  .ant-drawer-close {
    width: 32px;
    height: 32px;
    margin-left: 20px;
    margin-right: 0;
    background: url('../images/close-modal.svg');
    background-size: auto;
    padding: 0;
    background-repeat: no-repeat;
    background-position: center center;
    border: none;

    &:focus,
    &:hover {
      background-color: transparent;
      border: none;
      box-shadow: none;
    }

    .anticon {
      display: none;
    }
  }

  .ant-drawer-header,
  .ant-drawer-body {
    width: 100%;
  }

  .ant-drawer-header {
    padding: 24px 32px;
    border-bottom: none;
  }

  .ant-drawer-header-title {
    flex-direction: row-reverse;
  }

  .ant-drawer-title {
    font-size: 26px;
    line-height: 32px;
    font-family: $font-family-base;
    font-weight: 600;
    color: $color-base;
  }

  .ant-drawer-body {
    background-color: $body-bg;
    padding: 32px 12px 32px 32px;
    background-image: url(../images/drawer-shape.svg);
    background-position: left bottom;
    background-repeat: no-repeat;
  }

  .title-cap {
    border-bottom: 1px solid #bbbcbc;
    padding-bottom: 24px;
    margin-bottom: 24px;

    h2 {
      margin-bottom: 8px;
      font-size: 26px;
      color: $secondary;
      font-weight: 700;
    }

    p {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      margin: 0;
    }
  }
}

.lesson-detail {

  h5,
  p {
    font-size: 18px;
    line-height: 24px;
    color: $color-base;
    font-family: $font-family-sans;
  }

  h5 {
    font-weight: 700;
    margin-bottom: 8px;
  }

  p {
    margin-bottom: 24px;
  }
}

.btn-brand {
  .fa-solid {
    margin-right: 8px;
  }
}

.suggetion-out {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 16px;
}

.text-secondary {
  color: $secondary !important;
}

.text-danger {
  color: $danger !important;
}

.minute-dura {
  align-items: center;

  input {
    width: 52px;
    border: 1px solid #dddedd;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }

    &:focus {
      border-color: #257a8e;
    }
  }

  span {
    margin-left: 8px;
    font-size: 18px;
    line-height: 24px;
    color: #000;
  }
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.modal-head {
  p {
    margin-bottom: 24px;

    &.h4 {
      &:last-child {
        margin-bottom: 5px;
      }

      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-auto {
  margin-left: auto !important;
}

.d-block {
  display: block !important;
}

.ant-modal-wrap {
  .modal-head {
    h1 {
      font-weight: 600;
      line-height: normal;
    }
  }
}

.yellow-block {
  background-color: #fbdb8d;
  padding: 14px 16px;
  border-radius: 8px;
  margin-bottom: 16px;

  p {
    margin-bottom: 11px;
    display: flex;

    &:last-child {
      margin-bottom: 0;
    }

    strong {
      min-width: 80px;
      margin: 0 8px 0 0;
    }

    span {
      margin: 0;
    }
  }
}

.pt-0 {
  padding-top: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.work-detail {
  padding: 16px;
}

.work-d-list {
  +.work-d-list {
    margin-top: 24px;
  }

  label {
    margin-bottom: 0;
    margin-right: 8px !important;
  }

  ul {
    margin: 8px 0 0 0;
    display: inline-block;
    width: 100%;
    padding-left: 20px;

    li {
      list-style: outside;
      font-size: 16px;
      line-height: 20px;

      +li {
        margin-top: 8px;
      }

      a {
        color: inherit;
        text-decoration: underline;
      }
    }
  }
}

.event-word-card {
  .event-body {
    border-bottom: 1px solid $border-base;
  }
}

.showmore-less {
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 20px;
  margin: 0;
  font-weight: 600;
  cursor: pointer;

  &.open {
    i {
      transform: rotate(180deg);
    }
  }

  i {
    margin-left: 10px;
  }
}

.mr-8 {
  margin-right: 8px !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.ant-picker-calendar-date-content {
  .ant-btn {
    margin-top: 4px;
    padding: 4px 16px;
    font-size: 12px;
    color: #fff;
    height: auto !important;
    border-radius: 36px;
    border: none;
    text-align: left;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:before {
      content: '\e1da';
      font-family: 'Font Awesome 6 Pro';
      font-style: normal;
      font-weight: 400;
      margin-right: 8px;
    }
  }
}

.dash-container {
  max-width: 1300px;
  padding-right: 15px;
  padding-left: 15px;
}

.dashboard-header {
  background-color: #cde6e6;
  padding: 32px 0;

  .dash-logo {
    margin-left: 0;
    margin-right: 46px;

    img {
      max-width: 62px;
    }
  }

  h1 {
    margin-left: 0;
    margin-right: 0;
    color: $secondary;
    line-height: 48px;
    margin-bottom: 0;
    font-weight: 600;
  }

  .sign-out {
    margin-left: 10px;
    color: inherit;
    text-decoration: underline;
    font-size: 18px;
    font-weight: 500;

    &:hover {
      color: $brand-color;
    }
  }
}

.d-flex {
  display: flex !important;
}

.align-items-center {
  align-items: center !important;
}

.flex-fill {
  flex: 1 1;
}

.dashboard-body {
  padding-top: 48px;
}

.w-100 {
  width: 100%;
}

.dashboard-search {
  max-width: 640px;
  width: 100%;
  margin-right: 0;
}

.dash-toolbar {
  margin-bottom: 32px;
}

.search-btn {
  &.btn {
    background-color: #e6f2f3;

    &:hover {
      background-color: $btn-focus-color-bg;
    }
  }

  margin-left: 20px;
  border-width: 1px;
}

.search-area {
  position: relative;

  i {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
  }

  input {
    padding-left: 44px;
  }
}

.dash-table {
  width: 100%;
  border-collapse: collapse;

  thead {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 11;

    th {
      background-color: #257a8e;
      color: $white;
      font-size: 16px;
      line-height: 20px;
      padding: 12px 16px;
      font-weight: 700;
      text-align: left;
      border-bottom: 1px solid $border-base;

      +th {
        border-left: 1px solid #f8fafc;
      }

      &:first-child {
        border-top-left-radius: 8px;
      }

      &:last-child {
        border-top-right-radius: 8px;
      }
    }
  }

  tbody {
    background-color: $white;

    td {
      border-bottom: 1px solid $border-base;
      padding: 24px 16px;
      font-size: 16px;
      line-height: 20px;

      +td {
        border-left: 1px solid #f8fafc;
      }
    }
  }

  .view-link {
    color: $secondary;
    font-weight: 700;
    text-decoration: underline;
  }
}

.account-prof-modal {
  h2 {
    color: $secondary;
    line-height: 40px;
    margin-bottom: 24px;
    font-weight: 600;
  }

  .ant-modal-body {
    padding-bottom: 8px;
  }

  .ant-modal-footer {
    display: none;
  }
}

.stat {
  font-weight: 600;
  padding-left: 16px;
  position: relative;
  display: block;
  margin: 0;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 6px;
    width: 8px;
    height: 8px;
    border-radius: 100%;
  }
}

.stat-active {
  color: #047857;

  &:before {
    background-color: #047857;
  }
}

.stat-pending {
  color: #767979;

  &:before {
    border: 1px solid #767979;
  }
}

.stat-suspend {
  color: #b45309;

  &:before {
    border: 1px solid #b45309;
  }
}

.personal-info {
  background-color: #f9f9f9;
  padding: 24px 24px 0;
  border-radius: 16px;
  margin-bottom: 24px;

  .pi-block {
    label {
      color: #545857;
    }
  }
}

.pi-block {
  margin-bottom: 24px;

  label {
    color: #767979;
    margin-bottom: 8px;
    display: block;
    line-height: 20px;
  }

  .pi-value {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: $color-base;
  }
}

.row {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;

  [class*='col-'] {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.status-color-block {
  color: $color-base;
  line-height: 20px;
  border: 1px solid transparent;
  border-radius: 8px;
}

.green-block {
  border-color: #047857;
  background-color: #ecfdf5;

  .acc-stat-block,
  .acc-stat-active {
    border-color: #047857;
  }
}

.gray-block {
  border-color: #767979;
  background-color: #dddedd;

  .acc-stat-block,
  .acc-stat-active {
    border-color: #767979;
  }
}

.red-block {
  border-color: #b45309;
  background-color: #fffbeb;

  .acc-stat-block,
  .acc-stat-active {
    border-color: #b45309;
  }
}

.acc-stat-block {
  padding: 24px;
  border-bottom: 1px solid transparent;

  .acc-dropdownmenu {
    right: -19px;
  }

  label {
    font-weight: 500;
    margin-bottom: 16px;
  }
}

.last-act {
  padding: 16px 24px;

  label {
    font-weight: 500;
    margin-bottom: 8px;
  }

  div {
    font-weight: 700;
  }
}

.acc-stat-active {
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 7px 16px;
  background-color: $white;
}

.acc-dropdown {
  position: relative;
  margin: 0;

  &.open {
    .acc-btn {
      background-color: #193e47;
      color: #fcd34d;
    }

    .acc-dropdownmenu {
      display: block;
    }
  }
}

.acc-dropdownmenu {
  display: none;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 11;
  width: 210px;
  background-color: $white;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #dddedd;
  box-shadow: 0 2px 4px rgba(16, 24, 40, 0.1), 0 4px 6px rgba(16, 24, 40, 0.1);
  margin: 9px 0 0 0;

  li {
    list-style: none;
    padding: 0;

    +li {
      border-top: 1px solid #dddedd;
    }

    a {
      padding: 8px 16px;
      color: #193e47;
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 24px;

      &:hover {
        background-color: #fce7b3;
        font-weight: 700;

        span {
          color: #193e47;
        }
      }

      span {
        color: #989b9a;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 16px 0 0;
        transition: all 0.3s ease;
      }
    }
  }
}

.mydropdowns {
  position: relative;
  margin: 0;

  .btn {
    display: block;
    border: none;
    appearance: none;
    padding: 12px 16px;
    min-width: 150px;
    border: none;
    border: 1px solid #dddedd;
    font-size: 16px;
    padding-right: 48px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 8px;
    line-height: 22px;
    color: #193e47;
    font-weight: 500;
    font-family: 'IBM Plex Sans', sans-serif;
    position: relative;
    background: #fff;

    &:after {
      content: '';
      background-image: url('../images/select-icons.svg');
      background-size: 16px auto;
      background-position: center center;
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      position: absolute;
      right: 20px;
      top: 14px;
    }
  }

  &.open {
    .btn {
      border-radius: 8px 8px 0 0;

      &:after {
        transform: rotate(180deg);
      }
    }

    .mydropdowns-menu {
      display: block;
      border-radius: 0px 0px 8px 8px;
    }
  }
}

.mydropdowns-menu {
  display: none;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 11;
  width: 180px;
  background-color: $white;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #dddedd;
  box-shadow: 0 2px 4px rgba(16, 24, 40, 0.1), 0 4px 6px rgba(16, 24, 40, 0.1);
  margin: 0;
  margin-top: -1px;

  li {
    list-style: none;
    padding: 0;

    +li {
      border-top: 1px solid #dddedd;
    }

    a {
      padding: 8px 16px;
      color: #193e47;
      display: flex;
      align-items: center;
      font-size: 18px;
      padding-right: 0;
      line-height: 24px;

      span {
        .icon-edits {
          font-size: 22px;
        }
      }

      &:hover {
        background-color: #fce7b3;
        font-weight: 700;

        span {
          color: #193e47;
        }
      }

      span {
        color: #989b9a;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 8px 0 0;
        transition: all 0.3s ease;
      }
    }
  }
}

.acc-btn {
  width: 26px;
  height: 20px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #767979;
  cursor: pointer;
  margin: 0;
}

.d-block {
  display: block !important;
}

.admin-login-theme {
  background: url('../images/admin-bg-new.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: #193e47;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0;

  .admin-linner-outer {
    display: block;
    width: 96%;
    max-width: 705px;
  }

  .admin-linner {
    display: block;
    background: $white;
    width: 100%;
    padding: 30px;
    border-radius: 8px;
  }

  .title-row {
    .info-card {
      max-width: 100%;
    }

    .adminlogo {
      margin-bottom: 20px;
    }

    h1 {
      font-weight: 700;
      font-size: 45px;
      line-height: 56px;
    }

    p {
      font-size: 18px;
    }
  }

  .acard {
    max-width: 535px;

    &.info-card {
      background: #eef1f3;

      .caption {
        p {
          font-weight: 500;
        }
      }
    }
  }
}

.admin-model {
  h1 {
    margin-bottom: 20px;
  }

  .list-item {
    &.danger-list {
      p {
        color: $error-color;
      }
    }

    &.success-list {
      background: #ecfdf5;
      border: 1px solid #047857;

      p {
        color: #047857;
      }
    }

    li {
      padding-left: 0;

      p {
        font-size: 22px;
        margin: 0 0 10px;
        display: block;

        &:last-child {
          margin-bottom: 0;
        }

        strong {
          font-style: italic;
        }
      }
    }
  }
}

.model-footer-center {
  .subhead {
    margin-bottom: 0;
  }

  .ant-modal-footer {
    text-align: center;
    padding-bottom: 40px;
  }
}

.has-sort {
  position: relative;

  i {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    font-size: 14px;
  }
}

.model-footer-center {
  .subhead {
    margin-bottom: 0;
  }

  .ant-modal-footer {
    text-align: center;
    padding-bottom: 40px;
  }
}

.popup-loader {
  p {
    text-align: center;
    color: #257a8e;
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
    margin: auto;
  }
}

.maxw-328 {
  max-width: 328px !important;
}

.maxw-115 {
  max-width: 115px !important;
}

.italic-text {
  font-style: italic;
}

.instructional-popup {
  .ant-modal-footer {
    padding-top: 0;
  }
}

.fs-18 {
  font-size: 18px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mb-48 {
  margin-bottom: 48px !important;
}

.profile-setting-modal {
  .ant-modal-body {
    padding: 0;
  }

  .modal-head {
    padding: 30px 30px 25px;
  }

  .ant-modal-footer {
    padding: 5px 30px 30px;
  }

  .ant-tabs-nav-wrap {
    padding-bottom: 33px;

    .ant-tabs-nav-list .ant-tabs-tab:first-child {
      border-radius: 0px 8px 0px 0px;
    }

    .ant-tabs-nav-list .ant-tabs-tab:last-child {
      border-radius: 8px 0px 0px 0px;
    }
  }

  .default-profile-list {
    padding: 0 30px;
  }

  .ant-upload {
    margin-bottom: 20px;
  }
}

.user-statistic {
  border: 1px solid #cde6e6;
  padding: 8px 16px;
  border-radius: 8px;
  display: flex;
  color: #193e47;
  font-size: 16px;
  line-height: 20px;
  margin-left: 0;
  font-family: 'IBM Plex Sans', sans-serif;

  >div {
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 500;

    strong {
      display: block;
      font-weight: 700;
      font-size: 22px;
      line-height: 32px;
    }
  }

  .active-users {
    padding-right: 32px;
    border-right: 1px solid #cde6e6;
  }

  .total-users {
    padding-left: 32px;
  }
}

.align-items-end {
  align-items: flex-end !important;
}

.setting-heading {
  font-weight: 700;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 22px;
  line-height: 44px;
  border-bottom: 1px solid #dddedd;
  margin-bottom: 32px;
}

.pb-64 {
  padding-bottom: 64px !important;
}