.pill {
  display: flex;
  height: 18px;
  width: 90px;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;

  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  &.signedUp {
    background: #e3f4ee;
    color: #1c8d64;
  }

  &.inviteSent {
    background: #f1f1f1;
    color: #1a363e;
  }
}
